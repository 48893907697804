.claro__container__process__sucess__billing {
    padding: 0 10px;

    .claro__container__process__success__billing__header {
        padding: 10px;
        margin-bottom: 20px;
        background: rgba(218, 218, 218, 0.22);
        border-radius: 3px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: justify;

        .claro__container__process__success__billing__header__textBlack {
            color: #222222;
        }

        .claro__container__process__success__billing__header__textRed {
            color: #DA291C;
        }

        .claro__container__process__success__billing__header__textBoldRed {
            color: #DA291C;
            font-weight: bold;
        }
    }

    .claro__container__process__sucess__billing__body {

        .claro__container__process__sucess__billing__body__input {
            margin-bottom: 20PX;

            .claro__container__process__sucess__billing__body__input__label {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                margin-bottom: 2px;
            }

            .claro__container__process__sucess__billing__body__input__label.required {
                margin-left: 2px;
                color: #DA291C;
            }

            input {
                width: 100%;
                height: 35px;
                padding: 5px;
                box-sizing: border-box;
                border: 1px solid #C4C4C4;
                border-radius: 14px;
                text-align: center;
            }

            input::placeholder {
                text-align: center;
            }

            input.error {
                background: rgba(218, 41, 28, 0.1);
                border: 1px solid #DA291C !important;
            }

            .claro__container__process__sucess__billing__body__input__errorMessage {
                color: #DA291C !important;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                padding-left: 8px;
            }
        }
    }
}