.claro__container__categories {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    width: 80%;
    margin: 15px auto;

    .claro__container__categories__option {
        border-bottom: 1px solid rgb(214, 214, 214);
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #726C6C;

        .claro__container__categories__option__text {
            margin-bottom: 8px;
            cursor: pointer;
        }
    }

    .claro__container__categories__option.topup {
        border-bottom: 4px solid #B52217;
    }

    .claro__container__categories__option.bundle {
        border-bottom: 4px solid #FFAD17;
    }
}