.claro__container__selectedOffer {
    height: 130px;
    margin: 10px 10%;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    user-select: none;

    .claro__container__selectedOffer__header {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 5px;
        height: 40px;
        text-align: center;
    }

    .claro__container__selectedOffer__header.topup {
        background: #B52217;
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .claro__container__selectedOffer__header.bundle {
        background: #FFAD17;
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;

        @media only screen and (max-height: 580px),
        (max-width: 330px) {
            font-size: 10px;
        }
    }

    .claro__container__selectedOffer__body {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        overflow: hidden;
        gap: 4px;

        .claro__container__selectedOffer__body__socialMedia {
            display: flex;
            justify-content: center;
            margin-top: 5px;
            gap: 5px;
            flex-wrap: wrap;

            img {
                width: 15px;
                height: 15px;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .claro__container__selectedOffer__body__amountTopup {
            font-weight: 900;
            font-size: 30px;
            color: #000000;
            text-align: center;
        }

        .claro__container__selectedOffer__body__amountBundle {
            font-weight: 900;
            font-size: 20px;
            color: #000000;
        }

        .claro__container__selectedOffer__body__description {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            text-align: center;
            padding: 0 5px;

            @media only screen and (max-height: 580px),
            (max-width: 330px) {
                font-size: 10px;
            }
        }
    }
}