.claro__header {

    .claro__header__primary {
        padding-left: 13px;
        background-color: #B52217;
        height: 34px;
        display: flex;
        align-items: center;

        .claro__header__primary__greetings {
            font-weight: bold;
            font-size: 18px;
            color: #ffffff;
        }

        .claro__header__primary__msisdn {
            padding-left: 10px;
            font-weight: bold;
            font-size: 18px;
            color: #ffffff;
        }
    }

    .claro__header__secondary {
        padding-left: 13px;
        background-color: #da291c;
        height: 52px;
        display: flex;
        align-items: center;

        .claro__header__secondary__menuVIcon {
            width: 35px;
            position: absolute;
            right: 0;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .claro__header__secondary__menuVIcon__line {
                width: 100%;
                height: 2px;
                background-color: #FFFFFF;
                margin: 6px 0;
            }
        }

        .claro__header__secondary__claroIcon {
            height: 60%;
            width: auto;
            cursor: pointer;

            img {
                height: 100%;
            }
        }
    }
}