.claro__container__payment__options__remember {
   display: flex;
   align-items: center;
   cursor: pointer;

   .claro__container__payment__options__remember__button {
      width: 40px;
      height: 20px;
      background: #c4c4c4;
      border: 1px solid rgba(34, 34, 34, 0.19);
      box-sizing: border-box;
      border-radius: 100px;
      position: relative;
      display: flex;
      align-items: center;

      .claro__container__payment__options__remember__button__circle {
         position: absolute;
         width: 21px;
         height: 20px;
         background: #dd4937;
         border: 1px solid #b7202e;
         box-sizing: border-box;
         border-radius: 100px;
         left: 0;
      }

      .claro__container__payment__options__remember__button__circle.selected {
         left: unset;
         right: 0;
      }
   }

   .claro__container__payment__options__remember__button.selected {
      background: #dd4937;
      border: 1px solid #b7202e;
   }

   .claro__container__payment__options__remember__text {
      margin-left: 7px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #3C3C3C;
   }
}