.claro__container__termsAndConditionsOrPrivacity {
   padding: 30px 15px;
   margin-bottom: 160px;

   .claro__container__termsAndConditionsOrPrivacity__goHome {
      margin-bottom: 140px;
      position: fixed;
      background: #da291c;
      bottom: 0;
      padding: 15px;
      border-radius: 10px;
      z-index: 100;
      box-shadow: 0px 3px 15px rgba(210, 84, 132, 0.3);
      right: 20px;
      cursor: pointer;

      div {
         color: white;
         font-size: 1rem;
         display: flex;
         justify-content: center;
         align-items: center;
         text-decoration: none;

         svg {
            margin-right: 15px;

            .icon {
               fill: white;
               margin-right: 10px;
            }
         }
      }
   }

   h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #3C3C3C;
   }

   h3 {
      font-size: 18px;
      line-height: 2rem;
      margin-bottom: 10px;
      margin-top: 25px;
      color: #3C3C3C;
   }

   h5 {
      line-height: 1.3rem;
      line-height: 2rem;
      margin-bottom: 10px;
      font-weight: 600;
   }

   a {
      font-size: 0.9rem;
      line-height: 1.1rem;
      font-weight: 900;
      text-decoration: underline;
   }

   ul {
      list-style: circle !important;

      li {
         display: flex;
         flex-direction: column;
         margin-bottom: 15px;

         p {
            font-size: 0.9rem;
            line-height: 1.4rem;
            text-align: justify;
            hyphens: auto;
         }

         a {
            line-height: 1rem;
            font-weight: 600;
         }
      }

      .tab {
         margin-left: 20px !important;

         b {
            font-weight: 600;
         }
      }

      .tab-2 {
         margin-left: 35px;
      }
   }

   p {
      font-size: 0.9rem;
      line-height: 1.4rem;
      text-align: justify;
      hyphens: auto;
      margin-bottom: 10px;
   }
}