.claro__container__payment__options__newCard__selected {
    padding: 10px 0 10px 10px;
    border: 1px solid #B9B9B9;
    border-radius: 12px;

    .claro__container__payment__options__newCard__selected__form {

        .claro__container__payment__options__newCard__selected__form__numberCard {
            display: flex;
            width: 100%;
            align-items: center;
            overflow: hidden;

            .claro__container__payment__options__newCard__selected__form__numberCard__text {
                flex: 1;
                max-width: 40%;
                font-weight: 600;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: #000000;
            }

            input {
                flex: 1;
                max-width: 60%;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                background: #FFFFFF;
                border-bottom: 1px solid #AFAFAF;
                color: #AFAFAF;
                display: flex;
                text-align: center;
                margin-right: 10px;
            }

            input::placeholder {
                font-size: 10px;
            }
        }

        .claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc {
            display: flex;
            align-items: center;
            margin: 10px 0 15px 0;
            padding: 0 10px;

            .claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__monthAndYear {
                flex: 1;
                max-width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: #726C6C;

                .claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__monthAndYear__text {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #222222;
                    width: 105px;
                }

                .claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__monthAndYear__inputs {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #AFAFAF;

                    input {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        width: 50px;
                        margin: 0 5px;
                        text-align: right;
                        color: #726C6C;
                    }

                    input:nth-child(2) {
                        text-align: left;
                    }
                }
            }

            .claro__container__payment__options__newCard__selected__form__monthAndYearAndCvc__cvc {
                flex: 1;
                max-width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .claro__container__payment__options__newCard__selected__form__cvc__text {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #222222;
                }

                input {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    width: 70px;
                    text-align: center;
                    color: #726C6C;
                    border-bottom: 1px solid #AFAFAF;
                }
            }
        }
    }
}

.claro__container__payment__options__newCard__selected__remember {
    display: flex;
    align-items: center;
    justify-content: center;
}

.claro__container__payment__options__newCard__selected.claroPayPaymentAccountBi {
    margin: 10px 0 0 20px;
}

.claro__container__payment__options__newCard__selected.claroPayPaymentOptions {
    margin: 10px 0 0 10px;
}

.claro__container__payment__options__newCard__selected__remember.claroPayPaymentAccountBi {
    margin: 10px 0 0 20px;
}

.claro__container__payment__options__newCard__selected__remember.claroPayPaymentOptions {
    margin: 10px 0 0 10px;
}