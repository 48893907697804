@keyframes jump {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.claro__container__recurrenceBanner {
    display: flex;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 90%;
    margin: 20px auto;
    cursor: pointer;

    .claro__container__recurrenceBanner__icon {
        margin: auto 2px auto 5px;

        img {
            width: 25px;
            height: 37px;
        }
    }

    .claro__container__recurrenceBanner__text {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;

        .claro__container__recurrenceBanner__text__title {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #000000;
        }

        .claro__container__recurrenceBanner__text__redirect {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #000000;
            cursor: pointer;
        }
    }
}

.claro__container__recurrenceBanner.animate-jump {
    animation: jump 0.5s ease-in-out 6;
}