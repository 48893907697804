.claro__menu {
    user-select: none;

    .claro__menu__menuV {
        display: none;
        position: absolute;
        height: 300%;
        width: 100%;
        z-index: 10000;
        right: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 1) 85%, rgba(204, 204, 204, 0.5) 85%);
        box-sizing: border-box;
        overflow: hidden;

        .claro__menu__menuV__option {
            cursor: pointer;
            margin: 15px 0 15px 15%;
            display: flex;
            align-items: center;
            justify-content: start;
            transition: transform 0.3s ease;

            img {
                width: 30px;
                height: 30px;
                margin: 0 10px 0 15px;
            }

            span {
                padding-top: 5px;
                font-weight: 700;
                font-size: 18px;
                color: #726C6C;
            }
        }
    }

    .claro__menu__menuV__option.seleccionada {
        span {
            font-weight: 900;
            font-size: 25px;
        }

        img {
            width: 35px;
            height: 35px;
        }
    }
}

.claro__menu__menuV.visible {
    display: block;
}

.claro__menu__menuH {
    margin-bottom: 20px;
    padding: 0 12px 0 12px;
    background: #D9D9D9;
    height: 70px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .claro__menu__menuH__option {
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
            width: 30px;
            height: 30px;
        }

        span {
            margin-top: 3px;
            font-weight: 400;
            font-size: 7.5px;
            color: #000000;
        }
    }

    .claro__menu__menuH__option.seleccionada {
        transform: scale(1.20);
        padding-bottom: 5px;
        border-bottom: 2px solid black;
        margin: 0 5px;
    }
}