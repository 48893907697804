.claro__container__pagaTuPrestamo {

  .claro__container__pagaTuPrestamo__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 20px 0;

    .claro__container__pagaTuPrestamo__title__image {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .claro__container__pagaTuPrestamo__title__text {
      font-weight: 700;
      font-size: 30px;
      line-height: 28px;
      color: #FFAD17;
      margin-top: 10px;
    }

  }

  .claro__container__pagaTuPrestamo__subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 20px 0;
  }

  .claro__container__pagaTuPrestamo__options {
    background: #FFFFFF;
    margin: 0 27px;

    .claro__container__pagaTuPrestamo__options__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #FF8300;
      border-radius: 12px 12px 0 0;
      padding: 2px 0 2px 15px;
      min-height: 45px;

      .claro__container__pagaTuPrestamo__options__header__title {
        font-weight: 900;
        font-size: 15px;
        color: #FFFFFF;
      }

      .claro__container__pagaTuPrestamo__options__header__subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
    }

    .claro__container__pagaTuPrestamo__options__header.inactive {
      background-color: #B9B9B9;
    }

    .claro__container__pagaTuPrestamo__options__header.recarga {
      text-align: center;
    }

    .claro__container__pagaTuPrestamo__options__body {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 0 0 12px 12px;
      padding-bottom: 20px;

      .claro__container__pagaTuPrestamo__options__body__noDetails {

        .claro__container__pagaTuPrestamo__options__body__noDetails__title {
          margin: 5px 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          text-align: center;
          justify-content: center;
          color: #000000;
        }

        .claro__container__pagaTuPrestamo__options__body__noDetails__amount {
          font-weight: 900;
          font-size: 40px;
          line-height: 24px;
          display: flex;
          text-align: center;
          justify-content: center;
          color: #000000;
        }
      }

      .claro__container__pagaTuPrestamo__options__body__details {
        padding: 15px 15px 0px 15px;

        .claro__container__pagaTuPrestamo__options__body__details__debt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 20px;
          border-bottom: 1px solid #DADADA;

          .claro__container__pagaTuPrestamo__options__body__details__debt__header {
            display: flex;
            align-items: center;
            justify-content: center;

            .claro__container__pagaTuPrestamo__options__body__details__debt__header__title {
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              text-align: left;
              color: #3C3C3C;
              width: 50%;
              text-transform: uppercase;
            }

            .claro__container__pagaTuPrestamo__options__body__details__debt__header__amount {
              font-weight: 700;
              font-size: 32px;
              line-height: 34px;
              text-align: right;
              color: #DA291C;
              width: 50%;
              margin-left: 10px;
            }
          }

          .claro__container__pagaTuPrestamo__options__body__details__debt__body {
            width: 100%;

            .claro__container__pagaTuPrestamo__options__body__details__debt__body__title {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              text-align: right;
              color: #0097A9;
            }

            .claro__container__pagaTuPrestamo__options__body__details__debt__body__info {
              .claro__container__pagaTuPrestamo__options__body__details__debt__body__info__amount {
                font-weight: 400;
                font-size: 10px;
                line-height: 20px;
                text-align: right;
                color: #726C6C;
              }

              .claro__container__pagaTuPrestamo__options__body__details__debt__body__info__text {
                margin-top: 5px;
                font-weight: 600;
                font-size: 10px;
                line-height: 20px;
                text-align: center;
                color: #3C3C3C;
              }
            }
          }
        }

        .claro__container__pagaTuPrestamo__options__body__details__pack {
          margin-top: 15px;
          padding-bottom: 20px;
          border-bottom: 1px solid #DADADA;

          .claro__container__pagaTuPrestamo__options__body__details__pack__header {
            display: flex;
            align-items: center;
            justify-content: center;

            .claro__container__pagaTuPrestamo__options__body__details__pack__header__title {
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              text-align: left;
              color: #3C3C3C;
              width: 50%;
              text-transform: uppercase;
            }

            .claro__container__pagaTuPrestamo__options__body__details__pack__header__amount {
              font-weight: 700;
              font-size: 32px;
              line-height: 34px;
              text-align: right;
              color: #DA291C;
              width: 50%;
              margin-left: 10px;
            }
          }

          .claro__container__pagaTuPrestamo__options__body__details__pack__description {
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            text-align: right;
            color: #726C6C;
          }

          .claro__container__pagaTuPrestamo__options__body__details__pack__socialMedia {
            display: flex;
            margin-top: 20px;
            margin-left: 5px;
            gap: 5px;
            flex-wrap: wrap;

            img {
              width: 15px;
              height: 15px;
            }
          }
        }

        .claro__container__pagaTuPrestamo__options__body__details__total {
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          .claro__container__pagaTuPrestamo__options__body__details__total__title {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: right;
            color: #3C3C3C;
            width: 70%;
          }

          .claro__container__pagaTuPrestamo__options__body__details__total__amount {
            font-weight: 900;
            font-size: 32px;
            line-height: 34px;
            align-items: center;
            text-align: center;
            width: 30%;
            color: #DA291C;
          }
        }
      }
    }

    .claro__container__pagaTuPrestamo__options__viewMore {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      margin-top: 10px;
      text-align: center;
      justify-content: center;
      text-decoration-line: underline;
      padding-bottom: 10px;
      margin-bottom: 15px;
      cursor: pointer;
      color: #0097A9;

      img {
        width: 20px;
      }
    }

    .claro__container__pagaTuPrestamo__options__viewMore.inactive {
      color: #B9B9B9;
    }
  }

  .claro__container__pagaTuPrestamo__options.inactive {
    cursor: pointer;
  }
}