@import "./views/ClaroTyC/claroTyC.scss";
@import "./views/ClaroPayPaymentOptions/claroPayPaymentOptions.scss";
@import "./views/ClaroPayPaymentOptionsWithBi/claroPayPaymentOptionsWithBi.scss";
@import "./views/ClaroBuyNowPayLater/claroBuyNowPayLater.scss";
@import "./views/ClaroPagaTuPrestamo/claroPagaTuPrestamo.scss";
@import "./views/ClaroAdm/claroAdm.scss";
@import "./views/ClaroDemoBi/claroDemoBi.scss";
@import "./views/ClaroDeleteAccountBi/claroDeleteAccountBi.scss";
@import "./views/ClaroSendARecharge/claroSendARecharge.scss";

@import "./components/Footer/footer.scss";
@import "./components/CustomSwitch/customSwitch.scss";
@import "./components/SavedCard/savedCard.scss";
@import "./components/CarouselSwiper/carouselSwiper.scss";
@import "./components/RecurrenceBanner/recurrenceBanner.scss";
@import "./components/Login/login.scss";
@import "./components/SelectedOffer/selectedOffer.scss";
@import "./components/CarouselControls/carouselControls.scss";
@import "./components/Categories/categories.scss";
@import "./components/NewCard/newCard.scss";
@import "./components/Billing/billing.scss";
@import "./components/Menu/menu.scss";
@import "./components/Header/header.scss";
@import "./components/SavedAccount/savedAccount.scss";

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
}

input {
  outline: none;
  border: none;
  border-radius: 0;
  padding: 0;
  appearance: none;
}

button {
  border: none;
}

.claro {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .claro__container {
    width: 100%;
    max-width: 540px;
    margin: auto;
    padding: 0px 15px;

    .claro__container__process {
      margin-bottom: 200px;

      .claro__container__process__generalMessage {
        margin: 20px 0;
        padding: 0 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
      }

      .claro__container__process__image {
        margin: 20px;
        width: 90px;
        height: 90px;
      }

      .claro__container__process__imagelogoBi {
        width: 125px;
        height: 125px;
      }

      .claro__container__process__sucess {

        .claro__container__process__sucess__information__general {
          display: flex;
          text-align: center;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 20px 0;
          padding: 20px 5px;
          height: auto;
          background: #ffffff;
          box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
          border-radius: 17px;

          .claro__container__process__sucess__information__general__titleBilling {

            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
          }

          .claro__container__process__sucess__information__general__subtitleBilling {
            margin: 30px 20px 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
          }

          .claro__container__process__success__informations__package {

            .claro__container__process__success__informations__package__info {
              display: flex;
              flex-direction: column;

              p:nth-child(1) {
                margin: 0;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                color: #222222;
              }

              p:nth-child(2) {
                margin: 5px 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #da291c;

                span {
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 18px;
                  text-align: right;
                  color: #afafaf;
                  margin-left: 2px;
                }
              }
            }

            .claro__container__process__success__informations__package__amount {
              margin: 0;
              font-weight: 700;
              font-size: 28px;
              line-height: 34px;
              color: #2d2d2d;
            }
          }
        }

        .claro__container__process__sucess__information {
          display: flex;
          text-align: center;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: auto;
          background: #ffffff;

          .claro__container__process__sucess__information__image {
            width: 50px;
            height: 50px;
          }

          .claro__container__process__sucess__information__message {
            font-weight: 700;
            font-size: 17px;
            line-height: 22px;
            text-align: center;
            color: #222222;
            margin: 10px 0;
          }

          .claro__container__process__success__informations__package {

            .claro__container__process__success__informations__package__info {
              display: flex;
              flex-direction: column;

              p:nth-child(1) {
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                color: #2d2d2d;
                margin: 0;
              }

              p:nth-child(2) {
                margin: 5px 0;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                color: #da291c;

                span {
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 18px;
                  text-align: right;
                  color: #afafaf;
                  margin-left: 2px;
                }
              }
            }

            .claro__container__process__success__informations__package__amount {
              margin: 0;
              font-weight: bold;
              font-size: 22px;
              line-height: 28px;
              color: #2d2d2d;
            }
          }

          .claro__container__process__success__informations__bundle {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;

            .claro__container__process__success__informations__bundle__textBoldBlack {
              font-weight: bold;
              color: #222222;
            }

            .claro__container__process__success__informations__bundle__textBoldRed {
              font-weight: bold;
              color: #DA291C;
            }

            .claro__container__process__success__informations__bundle__textNormalBlack {
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #000000;
            }
          }
        }

        .claro__container__process__sucess__noteSms {
          margin: 25px 0;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 65px;
          background: rgba(218, 218, 218, 0.22);
          border-radius: 3px;

          p {
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #2d2d2d;
            margin-left: 10px;

            span {
              font-weight: bold;
            }
          }
        }

        .claro__container__process__success__informations__separator {
          margin: 40px auto;
          width: 95%;
          border: 1px solid #AFAFAF;
        }
      }

      .claro__container__process__error {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        padding: 20px 5px;
        height: auto;
        background: #ffffff;
        box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
        border-radius: 17px;

        .claro__container__process__error__imageEmoji {
          margin-left: 3px;
          width: 25px;
          height: 25px;
        }
      }

      .claro__container__process__waiting {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 5px;
        height: auto;
        background: #ffffff;
        box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
        border-radius: 17px;

        .claro__container__process__waiting__imageBi {
          margin: 20px;
          width: 65px;
          height: 65px;
        }
      }

      .claro__container__process__redirectBi {
        margin-bottom: 260px;

        .claro__container__process__redirectBi__information {
          display: flex;
          text-align: center;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          padding: 20px 5px;
          height: auto;
          background: #ffffff;
          box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
          border-radius: 17px;

          .claro__container__process__redirectBi__information__imageTiendasApps {
            width: 150px;
            height: 100px;
          }
        }

        .claro__container__process__redirectBi__actions {
          position: fixed;
          display: flex;
          z-index: 9999;
          left: 0;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          bottom: 100px;
          width: 100%;
          height: 150px;
          background-color: #FFFFFF;

          .claro__container__process__redirectBi__actions__btnIrApp {
            display: flex;
            width: 80%;
            max-width: 500px;
            height: 44px;
            justify-content: center;
            align-items: center;
            z-index: 9999;
            box-sizing: border-box;
            border-radius: 27px;
            font-weight: 700;
            font-size: 17px;
            line-height: 22px;
            color: #FFFFFF;
            background: #003865;
            border: 1px solid #003865;
            cursor: pointer;
            text-decoration: none;

            .claro__container__process__redirectBi__actions__logoBi {
              margin-right: 5px;
              margin-top: -2px;
              width: 35px;
              height: 35px;
            }
          }

          .claro__container__process__redirectBi__actions__btnRegresar {
            display: flex;
            width: 80%;
            max-width: 500px;
            height: 44px;
            justify-content: center;
            align-items: center;
            z-index: 9999;
            margin-top: 20px;
            box-sizing: border-box;
            border-radius: 27px;
            font-weight: 700;
            font-size: 17px;
            line-height: 22px;
            background: #FFFFFF;
            border: 1px solid #CD2014;
            opacity: 1;
            color: #CD2014;
            cursor: pointer;
          }

          @media (min-width: 768px) {
            bottom: 60px;
          }
        }
      }
    }

    .claro__container__button {
      position: fixed;
      display: flex;
      z-index: 9999;
      left: 0;
      justify-content: center;
      align-items: center;
      bottom: 100px;
      width: 100%;
      height: 80px;
      background-color: #FFFFFF;

      .claro__container__button__next,
      .claro__container__button__payment,
      .claro__container__button__paymentBi {
        display: flex;
        width: 80%;
        max-width: 500px;
        height: 44px;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        margin: 0 auto;
        box-sizing: border-box;
        border-radius: 27px;
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
        cursor: pointer;

        img {
          margin-right: 5px;
          margin-top: -2px;
          width: 35px;
          height: 35px;
        }
      }

      .claro__container__button__next,
      .claro__container__button__payment {
        background: #CD2014;
        border: 1px solid #CD2014;
        color: #FFFFFF;
      }

      .claro__container__button__paymentBi {
        color: #FFFFFF;
        background: #003865;
        border: 1px solid #003865;
      }

      .claro__container__button__next.disabled,
      .claro__container__button__payment.disabled {
        background: transparent;
        color: #cd2014 !important;
        border: 1px solid #cd2014;
        cursor: not-allowed;
      }

      .claro__container__button__paymentBi.disabled {
        background: #A9A9A9;
        border: 1px solid #A9A9A9;
        cursor: not-allowed;
        opacity: 0.5;
      }

      @media (min-width: 768px) {
        bottom: 60px;
      }
    }

    .claro__container__dualButton {
      display: flex;
      margin: 50px 0;
      gap: 10px;

      .claro__container__dualButton__enviar {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 44px;
        box-sizing: border-box;
        border-radius: 27px;
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
        border: 1px solid #CD2014;
        background: #CD2014;
        color: #ffffff;
        cursor: pointer;
      }

      .claro__container__dualButton__enviar.disabled {
        cursor: not-allowed;
      }

      .claro__container__dualButton__regresar {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 44px;
        box-sizing: border-box;
        border-radius: 27px;
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
        background: #FFFFFF;
        border: 1px solid #CD2014;
        opacity: 1;
        color: #CD2014;
        cursor: pointer;
      }
    }

    .claro__container__moreOptions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      padding: 5px 0;
      cursor: pointer;

      .claro__container__moreOptions__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #0097A9;
      }
    }

    .claro__container__selectRechargeNumber {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 15px auto;
      box-shadow: 0 0px 8px 0px rgb(0 0 0 / 15%);
      border-radius: 12px;
      justify-content: center;
      box-sizing: border-box;
      padding: 5px;

      .claro__container__selectRechargeNumber__option {
        display: grid;
        grid-template-columns: .05fr auto 2fr;
        cursor: pointer;

        .claro__container__selectRechargeNumber__option__image {
          width: 24px;
          height: 24px;
          margin-right: 3px;
        }

        .claro__container__selectRechargeNumber__option__circleNotAccepted {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          border: 2px solid #111827;
          margin: 2px 5px 2px 2px;
        }

        .claro__container__selectRechargeNumber__option__number {
          margin-left: 2px;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3C3C3C;
        }

        .claro__container__selectRechargeNumber__option__changeNumber {
          margin-left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          text-decoration-line: underline;
          color: #0097A9;
        }
      }
    }
  }
}