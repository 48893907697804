.claro__container__login {

    .claro__container__login__header {
        display: flex;
        flex-direction: column;
        align-items: center;

        .claro__container__login__header__text {
            margin-top: 10px;
            font-weight: 700;
            font-size: 24px;
            line-height: 20px;
            color: #3C3C3C;
        }

        .claro__container__login__header__image {
            margin-top: 10px;
            width: 38px;
            height: 56px;
        }
    }

    .claro__container__login__body {
        margin: 5px 0 120px 0;
        padding-bottom: 50px;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 12px;

        .claro__container__login__body__text {
            padding: 10px 15px 10px 15px;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #000000;
        }

        .claro__container__login__body__text.disabled {
            color: rgba(196, 196, 196, 0.768627);
        }

        .claro__container__login__body__input {
            display: flex;
            flex-direction: column;
            align-items: center;

            .claro__container__login__body__input__form {
                border: 1px solid #C4C4C4;
                border-radius: 14px;
                font-weight: 400;
                font-size: 24px;
                line-height: 22px;
                height: 50px;
                width: 80%;
                max-width: 300px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #040404;
            }

            .claro__container__login__body__input__form::placeholder {
                font-size: 20px;
            }

            .claro__container__login__body__input__form.disabled::placeholder {
                color: rgba(196, 196, 196, 0.768627);
            }

            .claro__container__login__body__input__errorMessage {
                padding: 5px;
                font-weight: 300;
                font-size: 14px;
                line-height: 24px;
                color: #DA291C !important;
                text-align: center;
            }

            .claro__container__login__body__input__resendMessage {
                padding: 5px;
                font-weight: 300;
                font-size: 14px;
                line-height: 24px;
                color: #000000 !important;
                text-align: center;
            }

            .claro__container__login__body__input__button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                width: 70%;
                max-width: 250px;
                height: 44px;
                cursor: pointer;
                box-sizing: border-box;
                background: #CD2014;
                border: 1px solid #CD2014;
                border-radius: 27px;
                font-weight: 700;
                font-size: 17px;
                line-height: 22px;
                color: #FFFFFF;
            }

            .claro__container__login__body__input__button:disabled {
                opacity: 1;
                background: #FFFFFF;
                border-color: #CD2014;
                color: #CD2014;
                cursor: not-allowed;
            }

            .claro__container__login__body__input__button__resend {
                margin-top: 10px;
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
                color: #0097A9;
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;

                p {
                    border-bottom: 1px solid #0097A9;
                }
            }
        }
    }
}