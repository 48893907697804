.claro__container__adm {

  .claro__container__adm__title {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 20px 0 30px 0;

    .claro__container__adm__title__image {
      width: 40px;
      height: 40px;
      margin-right: 20px;
    }

    .claro__container__adm__title__text {
      font-weight: 700;
      font-size: 30px;
      line-height: 20px;
      color: #FFAD17;
      margin-top: 10px;
    }

  }

  .claro__container__adm__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 200px;

    .claro__container__adm__content__arrow {
      width: 35px;
      height: 35px;
    }

    .claro__container__adm__content__offer {
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 12px;

      .claro__container__adm__content__offer__header {
        display: flex;
        height: 70px;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 900;
        font-size: 20px;
        line-height: 24px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        color: #FFFFFF;
        background: #726C6C;
      }

      .claro__container__adm__content__offer__body {
        flex-direction: column;

        .claro__container__adm__content__offer__body__description {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-top: 15px;
          text-align: center;
          color: #000000;
        }

        .claro__container__adm__content__offer__body__imageDescription {
          display: flex;
          justify-content: center;
          margin: 10px 0;
          gap: 25px;

          img {
            width: 25px;
            height: 25px;
          }
        }

        .claro__container__adm__content__offer__body__amount {
          font-weight: 900;
          font-size: 40px;
          color: #000000;
          text-align: center;
          margin: 10px 0;
        }

        .claro__container__adm__content__offer__body__validity {
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          text-align: center;
          margin: 15px 0;
        }
      }

      .claro__container__adm__content__offer__footer {
        align-items: center;
        text-align: justify;
        width: 95%;
        margin: 5px auto;
        padding: 5px;
        border-top: 1px solid #DADADA;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;

        span {
          font-weight: 600;
        }
      }
    }
  }
}