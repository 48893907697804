.claro__container__demoBi {

   .claro__container__demoBi__header {
      margin: 20px 0 20px;

      .claro__container__demoBi__header__image {
         display: block;
         width: 40px;
         height: 40px;
         margin: 0 auto;
      }

      .claro__container__demoBi__header__title {
         text-align: center;
         font-weight: 400;
         font-size: 18px;
         line-height: 24px;
         color: #000000;
      }
   }
}