.claro__container__carouselControls {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 200px;
    cursor: pointer;

    img {
        width: 24px;
        height: 24px;
        margin: auto 10px;
    }

    .claro__container__carouselControls__count {

        span {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            display: inline-block;
            margin: auto 5px;
            border: 1px solid rgb(182, 182, 182);
        }

        .active {
            height: 11px;
            width: 11px;
            background-color: #da291c;
            border: 1px solid #da291c;
        }
    }
}

.claro__container__carouselControls.becasMineco {
    margin: 10px 0 20px;
}