.claro__container__sendARecharge {

    .claro__container__sendARecharge__selectMsisdn {

        .claro__container__sendARecharge__selectMsisdn__header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .claro__container__sendARecharge__selectMsisdn__header__text {
                margin-top: 30px;
                font-weight: 700;
                font-size: 24px;
                line-height: 20px;
                color: #3C3C3C;
            }
        }

        .claro__container__sendARecharge__selectMsisdn__body {
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 20px 0 200px 0;
            padding: 20px 5px;
            height: auto;
            background: #ffffff;
            box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.15);
            border-radius: 17px;

            .claro__container__sendARecharge__selectMsisdn__body__text {
                margin: 20px 0;
                padding: 0 10px;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #000000;
            }

            .claro__container__sendARecharge__selectMsisdn__body__image {
                margin: 10px 0 30px 0;
                width: 38px;
                height: 56px;
            }

            .claro__container__sendARecharge__selectMsisdn__body__option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                padding: 0 15px;
                width: 100%;
                cursor: pointer;
            }

            .claro__container__sendARecharge__selectMsisdn__body__option__info {
                display: flex;
                align-items: center;
                width: 100%;

                div {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    color: #222222;
                }

                div:first-child {
                    justify-content: flex-end;
                    width: 40%;
                }

                div:last-child {
                    flex: 1;
                    justify-content: flex-start;
                    width: 60%;
                }
            }
        }
    }
}