.claro__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  z-index: 9999;

  .claro__footer__acceptCard {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 10px auto;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 5px;
    align-items: center;
    overflow: hidden;

    .claro__footer__acceptCard__logo {
      flex-basis: 20%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 30px;
        margin-right: 15px;
      }
    }

    .claro__footer__acceptCard__text {
      flex-basis: 80%;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      color: #000000;
    }

    .claro__footer__acceptCard__text.textBold {
      font-weight: bold;
    }
  }

  .claro__footer__termsAndConditionsOrPrivacity {
    display: flex;
    justify-content: space-between;
    margin: 5px 10px;

    .claro__footer__termsAndConditionsOrPrivacity__text {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: #000000;
      text-decoration: none;
    }

    .claro__footer__termsAndConditionsOrPrivacity__text:last-child {
      margin-right: 0px;
    }
  }

  .claro__footer__logos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
    height: 22px;

    img {
      height: 22px;
      margin-right: 15px;
    }

    img:last-child {
      margin-right: 0px;
    }

    .claro__footer__logos__bi {
      height: 35px;
      width: 35px;
      margin-right: 15px;
    }
  }
}

@media (min-width: 768px) {
  .claro__footer {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 10px 0;

    .claro__footer__acceptCard {
      width: 50%;
      margin: 0 10px 0 10px;
    }

    .claro__footer__termsAndConditionsOrPrivacity {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: left;

      .claro__footer__termsAndConditionsOrPrivacity__text {
        font-size: 14px;
      }

      .claro__footer__termsAndConditionsOrPrivacity__text:first-child {
        margin-bottom: 0px;
        margin-right: 15px;
      }
    }

    .claro__footer__logos {
      display: flex;
      justify-content: center;
      width: 50%;
      margin-bottom: 0px;
      align-items: center;
      height: 30px;

      img {
        height: 30px;
        margin-right: 15px;
      }
    }
  }
}