.claro__container__payment__optionsWithBi {
   display: flex;
   flex-direction: column;
   margin: 20px 0 200px 0;
   border-top: 1px solid #DADADA;

   .claro__container__payment__optionsWithBi__title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;      
      color: #000000;      
   }

   .claro__container__payment__optionsWithBi__arrow {
      padding-top: 2px;
   }

   .claro__container__payment__optionsWithBi__viewOther {
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0097A9;
      margin-top: 20px;
      padding-left: 10px;

      img {
         width: 20px;
         margin-right: 10px;
      }
   }

   .claro__container__payment__optionsWithBi__newMethod {
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0097A9;
      margin-top: 20px;
      padding-left: 10px;

      img {
         width: 20px;
         margin-right: 10px;
      }
   }

   .claro__container__payment__optionsWithBi__newAccount {
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0097A9;
      margin: 20px 0 0 20px;

      img {
         width: 20px;
         margin-right: 10px;
      }

      div {
         &:last-child {
            flex: 1;
            justify-content: flex-end;
         }
      }

      .claro__container__payment__optionsWithBi__newAccount__logo {
         display: flex;

         img {
            width: 32px;
         }
      }
   }

   .claro__container__payment__optionsWithBi__newCard {
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0097A9;
      margin: 20px 0 0 20px;

      img {
         width: 20px;
         margin-right: 10px;
      }
   }
}