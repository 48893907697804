.claro__container__payment__optionsWithBi__account {
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0097A9;
    margin-top: 20px;
    padding-left: 10px;

    img {
        width: 20px;
        margin-right: 10px;
    }

    div {
        &:last-child {
            flex: 1;
            justify-content: flex-end;
        }
    }

    .claro__container__payment__optionsWithBi__account__logo {
        display: flex;

        img {
            width: 32px;
        }
    }
}