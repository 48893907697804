.claro__container__payment__options__savedCard {
   height: 32px;
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   display: flex;
   align-items: center;
   color: #0097A9;
   margin-top: 20px;
   padding-left: 10px;

   img {
      width: 20px;
      margin-right: 10px;
   }

   div {
      &:last-child {
         flex: 1;
         justify-content: flex-end;
      }
   }

   .claro__container__payment__options__savedCard__logo {
      display: flex;

      img {
         width: 32px;
      }
   }
}

.claro__container__payment__options__savedCard__selected {
   margin-top: 20px;
   padding: 10px 0 0 10px;
   border: 1px solid #B9B9B9;
   border-radius: 12px;

   .claro__container__payment__options__savedCard__selected__header {
      display: flex;
      width: 100%;
      align-items: center;

      .claro__container__payment__options__savedCard__selected__header__circle img {
         width: 20px;
      }

      .claro__container__payment__options__savedCard__selected__header__content {
         display: flex;
         align-items: center;
         gap: 7px;
         margin: auto;

         .claro__container__payment__options__savedCard__selected__header__content__logo img {
            width: 32px;
         }

         .claro__container__payment__options__savedCard__selected__header__content__number {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #0097A9;
         }
      }
   }

   .claro__container__payment__options__savedCard__selected__body {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 8px 0;

      .claro__container__payment__options__savedCard__selected__body__text {
         font-weight: 600;
         font-size: 14px;
         line-height: 24px;
         display: flex;
         align-items: flex-start;
         text-align: center;
         color: #000000;
         padding-top: 5px;
         margin-right: 5px;
      }

      .claro__container__payment__options__savedCard__selected__body__form {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         text-align: center;
         color: #726C6C;

         input {
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            width: 70px;
            text-align: center;
            color: #726C6C;
         }

         .claro__container__payment__options__savedCard__selected__body__form__text {
            font-weight: 400;
            font-size: 10px;
            display: block;
            text-align: center;
            color: #000000;
         }

         .claro__container__payment__options__savedCard__selected__body__form__inputs {
            display: flex;
            align-items: center;
         }
      }
   }
}

.claro__container__payment__options__savedCard__selected__remember {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 10px;
}