.claro__container__carousel {
    margin-top: 20px;

    .claro__container__carousel__content {
        width: 170px;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        margin-bottom: 5px;

        .claro__container__carousel__content__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            padding: 2px 0;
            height: 66px;
            color: #FFFFFF;
            font-weight: 900;
            font-size: 22px;
            line-height: 24px;
        }

        .claro__container__carousel__content__header.bundle {
            background: #FFC721;
        }

        .claro__container__carousel__content__header.bundle.active {
            background: #FFAD17;
        }

        .claro__container__carousel__content__header.topup {
            background: #da291c;
        }

        .claro__container__carousel__content__header.topup.active {
            background: #B52217;
        }

        .claro__container__carousel__content__body {
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 120px;
            padding: 4px;

            .claro__container__carousel__content__body__amount {
                font-weight: 900;
                font-size: 35px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #000000;
            }

            .claro__container__carousel__content__body__description {
                margin-top: 30px;
                font-weight: 400;
                font-size: 16px;
                line-height: 12px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #000000;
            }
        }
    }
}

.swiper {
    user-select: none;
}

.swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: auto 5px;
    border: 1px solid rgb(182, 182, 182);
}

.swiper-pagination-bullet-active {
    height: 11px;
    width: 11px;
    background-color: #da291c;
    border: 1px solid #da291c;
}

.claro__container__carousel__controls {
    display: flex;
    gap: 10px;
    margin: 20px 0 200px 0;
    align-items: center;
    justify-content: center;

    .swiper-button-prev,
    .swiper-button-next {
        position: unset !important;
        margin-top: unset !important;
        color: #20A4B4 !important;
        width: unset !important;
        height: unset !important;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        padding: 0 10px;
        font-size: 20px !important;
    }
}