.claro__container__delete__accountBi {
    width: 100%;

    .claro__container__delete__accountBi__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 0 15px;
        width: 100%;
        cursor: pointer;

        .claro__container__delete__accountBi__content__information {
            display: flex;
            align-items: center;
            width: 100%;

            div {
                padding: 5px;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #222222;

                &:first-child {
                    flex: 1;
                    justify-content: flex-start;
                    width: 10%;
                }

                &:nth-child(2) {
                    flex: 8;
                    justify-content: left;
                    width: 80%;

                    p {
                        text-align: left;
                    }
                }

                &:last-child {
                    flex: 1;
                    justify-content: flex-end;
                    width: 10%;
                }
            }
        }
    }
}