.claro__container__bnpl {

	.claro__container__bnpl__options {
		display: flex;
		flex-direction: column;
		margin: 5px 0 40px 0;
		padding: 20px 0;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
		border-radius: 12px;
		font-size: 14px;
		color: #454545;

		.claro__container__bnpl__options__body {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 0 10px;

			.claro__container__bnpl__options__body__image {
				width: 30px;
			}

			.claro__container__bnpl__options__body__title {
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
			}

			.claro__container__bnpl__options__body__subtitle {
				margin-top: 10px;
				color: #0097A9;
				text-align: center;
			}

			.claro__container__bnpl__options__body__description {
				margin-top: 15px;
				font-size: 16px;
				font-weight: bold;
				color: #454545;
			}

			.claro__container__bnpl__options__body__amount {
				font-weight: bold;
				color: #DA291C;
				font-size: 32px;
			}

			.claro__container__bnpl__options__body__details {
				font-weight: 400;
				font-size: 12px;
				line-height: 20px;
				text-align: center;
				color: #726C6C;

				span {
					font-weight: bold;
				}
			}
		}

		.claro__container__bnpl__options__viewMore {
			margin-top: 20px;
			color: #0097A9;
			font-weight: bold;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 20px;
			}
		}
	}
}